import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"

import { FBIcon, InstagramIcon } from "./socialIcons"

const NavLinkMobile = props => {
  const { children, ...rest } = props
  return (
    <Link
      css={css({
        fontSize: "2rem",
      })}
      {...rest}
    >
      <div>{props.children}</div>
    </Link>
  )
}

const NavbarSocialLinks = props => {
  return (
    <>
      <div
        css={css`
          display: grid;
          grid-gap: 2rem;
          margin-bottom: 4rem;
        `}
      >
        <NavLinkMobile to="/apie-mus">Apie mus</NavLinkMobile>
        <NavLinkMobile to="/projektai">Projektai</NavLinkMobile>
        <NavLinkMobile to="/paslaugos">Paslaugos</NavLinkMobile>
        <NavLinkMobile to="/kontaktai">Kontaktai</NavLinkMobile>
      </div>
      <div
        css={css`
          display: grid;
          grid-gap: 1rem;
          grid-auto-flow: column;
        `}
      >
        <a
          title="Facebook page"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/Laumesarchitects"
        >
          <FBIcon
            css={css`
              height: 40px;
            `}
          />
        </a>
        <a
          title="Instagram feed"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/laumes_architects"
        >
          <InstagramIcon
            css={css`
              height: 40px;
            `}
          />
        </a>
      </div>
    </>
  )
}

export default NavbarSocialLinks
