import React, { useState } from "react"
import CookieConsent, { Cookies } from "react-cookie-consent"
import { Global, css } from "@emotion/core"
import { Link } from "gatsby"

import { FBIcon, InstagramIcon, PinterestIcon, InfoIcon } from "./socialIcons"
import { Screen } from "../responsive"
import { theme } from "./theme"
import { Css } from "./css"
import "./normalize.css"
import "./layout.scss"
import NavbarLinks from "./NavBarLinks"
import NavbarSocialLinks from "./NavBarSocialLinks"

import kpfLogo from "../images/kpf_logo.jpeg"
import logo from "../images/laumes-logo-color.svg"
import { constants } from "../constants"

const HeaderLink = props => {
  return (
    <Link
      css={css`
              font-size: 0.9rem;
              font-weight: 300;
              color: theme.colors.lightText;
              letter-spacing: 0.5,
              display: inline-block;
              margin: 0 1vw;
              transition: all 200ms ease-in;
              position: relative;
              justify-content: center;
              
              :after {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  width: 0%;
                  content: ".";
                  color: transparent;
                  background: #ec2024;
                  height: 1px;
                  transition: all 0.4s ease-in;
              }
              
              :hover {
                  color: #ec2024;
                  ::after {
                  width: 100%;
                  }
              }
              `}
      {...props}
    ></Link>
  )
}

const Toggle = props => {
  return (
    <div
      css={css`
        display: none;
        height: 100%;
        cursor: pointer;
        padding: 0 10vw;

        @media (max-width: 768px) {
          display: flex;
        }
      `}
      {...props}
    ></div>
  )
}

const NavBox = props => {
  return (
    <div
      css={css`
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;

        @media (max-width: 768px) {
          flex-direction: column;
          position: fixed;
          width: 100%;
          justify-content: flex-start;
          padding-top: 10vh;
          background-color: #fff;
          transition: all 0.3s ease-in;
          top: 64px;
          left: ${props.open ? "-100%" : "0"};
        }
      `}
      {...props}
    ></div>
  )
}

const NavBoxIcons = props => {
  return (
    <div
      css={css`
        display: flex;
        height: 100%;
        justify-content: end;
        align-items: center;

        @media (max-width: 768px) {
          flex-direction: column;
          position: fixed;
          width: 100%;
          justify-content: flex-start;
          padding-top: 10vh;
          background-color: #fff;
          transition: all 0.3s ease-in;
          top: 64px;
          left: ${props.open ? "-100%" : "0"};
        }
      `}
      {...props}
    ></div>
  )
}

const Hamburger = props => {
  return (
    <div
      css={css`
        background-color: #111;
        width: 30px;
        height: 3px;
        transition: all 0.3s linear;
        align-self: center;
        position: relative;
        transform: ${props.open ? "rotate(-45deg)" : "inherit"};

        ::before,
        ::after {
          width: 30px;
          height: 3px;
          background-color: #111;
          content: "";
          position: absolute;
          transition: all 0.3s linear;
        }

        ::before {
          transform: ${props.open
            ? "rotate(-90deg) translate(-10px, 0px)"
            : "rotate(0deg)"};
          top: -10px;
        }

        ::after {
          opacity: ${props.open ? "0" : "1"};
          transform: ${props.open ? "rotate(90deg) " : "rotate(0deg)"};
          top: 10px;
        }
      `}
      {...props}
    ></div>
  )
}

export const Layout = ({ children }) => {
  return (
    <div>
      <Navigation />
      <Global
        styles={{
          html: {
            backgroundColor: "#fff",
            color: "#111",
            fontFamily: `'Poppins', sans-serif`,
            fontSize: 14,
            [Screen.S]: {
              fontSize: 16,
            },
            [Screen.M]: {
              fontSize: 18,
            },
            [Screen.L]: {
              fontSize: 20,
            },
          },
          a: {
            color: "unset",
          },
        }}
      />
      <div
        css={{
          paddingTop: "var(--topmenuHeight)",
        }}
      >
        {children}
      </div>
      <Footer />
    </div>
  )
}

function Navigation() {
  const [navbarOpen, setNavbarOpen] = useState(false)
  return (
    <header
      css={{
        width: "100%",
        position: "fixed",
        background: "#fff",
        zIndex: "100",
        top: 0,
        height: "var(--topmenuHeight)",
      }}
    >
      <div
        css={{
          gridAutoFlow: "column",
          display: "grid",
          maxWidth: 1200,
          height: "100%",
          margin: "0 auto",
          gridTemplateColumns: "auto 1fr",
          alignItems: "center",
          paddingLeft: 35,
        }}
      >
        <Link to="/">
          <img style={{ height: "2.5rem" }} src={logo} alt="Laumes logo" />
        </Link>

        <Toggle
          navbarOpen={navbarOpen}
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          {navbarOpen ? <Hamburger open /> : <Hamburger />}
        </Toggle>
        {navbarOpen ? (
          <NavBoxIcons>
            <NavbarSocialLinks />
          </NavBoxIcons>
        ) : (
          <NavBox open>
            <div>
              <HeaderLink to="/apie-mus">Apie mus</HeaderLink>
              <HeaderLink to="/projektai">Projektai</HeaderLink>
              <HeaderLink to="/paslaugos">Paslaugos</HeaderLink>
              {/* <HeaderLink>Naujienlaiškis</HeaderLink> */}
              <HeaderLink to="/kontaktai">Kontaktai</HeaderLink>
            </div>
            <div>
              <NavbarLinks />
            </div>
          </NavBox>
        )}
      </div>
    </header>
  )
}

function Footer() {
  return (
    <footer
      css={{
        padding: "6rem 2rem",
        fontSize: "1rem",
        minHeight: 160,
        fontFamily: "sans-serif",
        textAlign: "center",
        ...Css.container,
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "3.6rem",
        }}
      >
        <div
          css={{
            fontSize: "1.2rem",
            display: "grid",
            gridGap: "0.8rem",
          }}
        >
          <a href="mailto:info@laumes.lt" css={{ textDecoration: "unset" }}>
            <span style={{ marginBottom: "0.5rem" }}>info@laumes.lt</span>
          </a>
          <a
            href={`tel:${constants.mainPhone.value}`}
            css={{ textDecoration: "unset" }}
          >
            <span>{constants.mainPhone.formattedValue}</span>
          </a>
        </div>
      </div>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          alignItems: "center",
          fontWeight: "lighter",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            color: theme.colors.lightText,
          }}
        >
          <span>Laumės</span>
          <span>Architektūros studija</span>
        </div>
      </div>

      <div css={{ marginTop: "4rem" }}>
        <img
          title="Europos žemės ūkio fondas kaimo plėtrai"
          alt="Europos žemės ūkio fondas kaimo plėtrai"
          src={kpfLogo}
        />
      </div>

      <CookieConsent
        buttonText="Accept"
        declineButtonText="Don't accept"
        cookieName="gatsby-gdpr-google-analytics"
        style={{
          backgroundColor: "white",
          color: "black",
          fontSize: "15px",
          fontWeight: "200",
          paddingLeft: "5rem",
          fontFamily: `'Poppins', sans-serif`,
          width: "100%",
        }}
        buttonStyle={{
          color: "black",
          backgroundColor: "white",
          marginRight: "5rem",
          fontWeight: "400",
          borderRadius: "12px",
          fontFamily: `'Poppins', sans-serif`,
          borderColor: "black",
          boxShadow: "0 1px 4px 0 rgba(0,0,0,0.4), 0 1px 9px 0 rgba(0,0,0,0.4)",
        }}
      >
        <InfoIcon />
        This website uses cookies to enhance the user experience.
        <Link
          to="/cookies/"
          css={{
            fontSize: "15px",
            textDecoration: "none",
            fontStyle: "italic",
            fontWeight: 300,
            color: "black",
            letterSpacing: 0.5,
          }}
        >
          More information.
        </Link>
      </CookieConsent>
    </footer>
  )
}

export function PageTitle(props) {
  return (
    <h1
      css={{ textAlign: "center", marginTop: "2rem", marginBottom: "4rem" }}
      {...props}
    ></h1>
  )
}

export default Layout
export { Navigation, Footer }
