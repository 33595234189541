export const theme = {
  font: {
    primary: `"Libre Baskerville", serif`,
    secondary: `"Poppins", sans-serif`,
  },
  colors: {
    text: "#414145",
    lightText: "#3131397a",
  },
}
