import { css, InterpolationWithTheme, Interpolation } from "@emotion/core"

import { Screen } from "../responsive"

export const Css = {
  container: {
    maxWidth: "60rem",
    margin: `0 auto`,
  } as Interpolation,
  responsiveSection: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fit, minmax(350px, 1fr))`,
    textAlign: "center",
    gridGap: "2em",
    [Screen.M]: {
      alignItems: "start",
      textAlign: "left",
    },
  },
}
