import React, { useState } from "react"
import logo from "../images/laumes-logo-color.svg"
import CookieConsent, { Cookies } from "react-cookie-consent"
import { Global, css } from "@emotion/core"
import { Link } from "gatsby"
import { FBIcon, InstagramIcon, PinterestIcon, InfoIcon } from "./socialIcons"
import { Screen } from "../responsive"
import { theme } from "./theme"
import { Css } from "./css"

const HeaderLink = props => {
  return (
    <Link
      css={css`
                font-size: 0.9rem;
                font-weight: 300;
                color: theme.colors.lightText;
                letter-spacing: 0.5,
                display: inline-block;
                margin: 0 1vw;
                transition: all 200ms ease-in;
                position: relative;
                justify-content: center;
                
        
                :after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 0%;
                    content: ".";
                    color: transparent;
                    background: #ec2024;
                    height: 1px;
                    transition: all 0.4s ease-in;
                }
                
                :hover {
                    color: #ec2024;
                    ::after {
                    width: 100%;
                    }
                }
                `}
      {...props}
    ></Link>
  )
}

const NavbarLinks = props => {
  return (
    <>
      <div>
        <a
          title="Facebook page"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/Laumesarchitects"
          css={{
            marginRight: "0.5rem",
          }}
        >
          <FBIcon />
        </a>
        {/* <a
          title="Pinterest page"
          target="_blank"
          rel="noopener noreferrer"
          css={{
            marginRight: "0.5rem",
          }}
        >
          <PinterestIcon />
        </a> */}
        <a
          title="Instagram feed"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/laumes_architects"
          css={{
            marginRight: "0.5rem",
          }}
        >
          <InstagramIcon />
        </a>
      </div>
    </>
  )
}

export default NavbarLinks
